export default {
  init() {
    // JavaScript to be fired on the home page
    function parallax() {
      var scroll = window.pageYOffset || document.documentElement.scrollTop;
      var l1 = document.querySelector('.l1');
      var l1coord = (scroll * 0.65) + 'px'
        l1.style.transform = 'translateY('+l1coord+')';
      var l2 = document.querySelector('.l2');
      var l2coord = (scroll * 0.6) + 'px'
        l2.style.transform = 'translateY('+l2coord+')';
      var l3 = document.querySelector('.l3');
      var l3coord = (scroll * 0.55) + 'px'
        l3.style.transform = 'translateY('+l3coord+')';
      var l4 = document.querySelector('.l4');
      var l4coord = (scroll * 0.4) + 'px'
        l4.style.transform = 'translateY('+l4coord+')';
      var l5 = document.querySelector('.l5');
      var l5coord = (scroll * 0.2) + 'px'
        l5.style.transform = 'translateY('+l5coord+')';
    }
    window.addEventListener('scroll', parallax);

  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
